
export default function HomeScreen() {
  const people = [
    {
      name: 'Charles Ndlovu',
      role: 'Communications',
      imageUrl: require('../images/Charles.jpeg'),
      bio: "Charles Ndlovu is a sophomore majoring in Business, he is a recent graduate of Waterford Kamhlaba UWC. As part of the Ndebele tribe, he has insight on how his tribe can foster cooperation. He has worked with Sethule Trust on various youth empowerment and rural development projects.",
    },
    {
      name: 'Rufaro Gamariel',
      role: 'Budgeting',
      imageUrl: require('../images/Rufaro.jpg'),
      bio: "Rufaro Gamariel is another UWC graduate from the Atlantic College, he is a sophomore majoring in Biomedical Engineering. As a member of the Shona tribe with relatives in the Ndebele tribe he experienced firsthand the challenges and opportunities associated with intertribal relations.",
    },
    {
      name: 'Kudakwashe Rumawu',
      role: 'Planning and Logistics',
      imageUrl: require('../images/Kuda.jpeg'),
      bio: "Kudakwashe Rumawu is a junior studying Data Science, he is a Mastercard Foundation Scholar and also a Yale Young African Scholar. He belongs to the Shona tribe and is passionate about addressing tribal hierarchies, privilege and other sources of conflict.",
    },
    {
      name: 'Samuel Henderson',
      role: 'Website and Media',
      imageUrl: require('../images/Samuel.jpg'),
      bio: "Samuel Henderson is also a sophomore majoring in Computer Science; he is also a recent graduate of Robert Bosch UWC and a Yale Young Global Scholar. He hopes to use his software development skills and UWC education to tackle global challenges.",
    },
  ]

  return (
    <div className="bg-white">
      <main>
        {/* Hero section */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src={require('../images/students.jpg')}
                  alt="Students"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-gray-300 to-indigo-300 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">Ending bullying and other forms of</span>
                  <span className="block text-white">discrimination in Zimbabwe</span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                  Younity Camp is a free 2 week intensive summer experience near Bulawayo, Zimbabwe.
                </p>
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                    <a
                      href="/apply"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                    >
                      Apply
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Logo Cloud */}
        <div className="bg-gray-100">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
              Made possible thanks to these organizations
            </p>
            <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-2 lg:grid-cols-2">
              {/* <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src={require('../images/DavisLogo.jpeg')} alt="Davis" />
              </div> */}
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src={require('../images/SethuleLogo.png')} alt="Sethule" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img
                  className="h-12"
                  src={require('../images/UofRLogo.png')}
                  alt="University of Rochester"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative pt-16 pb-32 overflow-hidden">
          <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
          <div className="relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div className="mt-6">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                      A free educational summer camp
                    </h2>
                    <p className="mt-4 text-lg text-gray-500">
                      A summer camp that takes a three-fold approach to addressing conflict brought by 
                      discrimination and bullying; Awareness, Action & Planning Ahead. 
                      Through Awareness we aim to get rid of myths, stereotypes and subconscious 
                      biases between tribes, discuss the signs of tribal othering, consequences of blame 
                      shifting and pointing fingers. To discuss further about bullying and its detrimental effects 
                      on both the victim and the perpetrator, we will also engage with parents of participants on 
                      how the topic of bullying factors in when children are brought up. Action seeks to engage 
                      attendees in physical and intellectual activities that aim to stimulate reflection, challenge 
                      the body and mind.  Integrated teams will allow for multi-tribal cooperation, recognition of 
                      similarity, celebration of diversity and appreciation of mutual interests beyond tribal lines, 
                      physical looks and socioeconomic backgrounds.  Planning ahead involves comprehensive planning on 
                      how participants plan to have an impact in their communities and beyond, anticipated challenges 
                      and support they would need.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={require('../images/studentsYoung.jpeg')}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Team section */}
        <div className="bg-white">
          <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
              <div className="space-y-5 sm:space-y-4">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Our Team</h2>
                <p className="text-xl text-gray-500">
                  Meet the team behind the Younity Camp!
                </p>
                <h3 className="text-xl font-extrabold tracking-tight sm:text-xl pt-2">Vision</h3>
                <p className="text-xl text-gray-500">
                  To foster a tranquil and vibrant community that allows the free and 
                  genuine coexistence of diverse cultural groups.
                </p>
                <h3 className="text-xl font-extrabold tracking-tight sm:text-xl">Mission statement</h3>
                <p className="text-xl text-gray-500">
                  To challenge and develop the mindset of the Zimbabwean youths through sparking constructive 
                  conversation about tribalism and bullying, through effective team building activities and promoting 
                  ideation of ways to curb conflict in the country.
                </p>
              </div>
              <div className="lg:col-span-2">
                <ul
                  className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
                >
                  {people.map((person) => (
                    <li key={person.name}>
                      <div className="space-y-4">
                        <div className="aspect-w-9 aspect-h-10">
                          <img className="object-cover shadow-lg rounded-lg" src={person.imageUrl} alt="Team member" />
                        </div>
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3>{person.name}</h3>
                          <p className="text-indigo-600">{person.role}</p>
                        </div>
                        <div className="text-lg">
                          <p className="text-gray-500">{person.bio}</p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-white">
          <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Want to participate?</span>
              <span className="-mb-1 pb-1 block bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
                Apply for free.
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
              <a
                href="/apply"
                className="flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
              >
                Apply
              </a>
              <a
                href="/contact"
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-indigo-50 hover:bg-indigo-100"
              >
                Get in touch
              </a>
            </div>
          </div>
        </div>
      </main>

    </div>
  )
}
