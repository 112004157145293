import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import PageNotFoundScreen from './screens/404Screen'
import ContactScreen from './screens/ContactScreen'
import ApplyScreen from './screens/ApplyScreen'
import ThankYouScreen from './screens/ThankYouScreen'

const App = () => {
  return (
    <>
      <Header/>
      <Router>
        <Routes>
          {/* <OfferScreen /> */}

          <Route path='/' element={<HomeScreen/>} exact/>\
          <Route path='/contact' element={<ContactScreen/>} exact/>\
          <Route path='/apply' element={<ApplyScreen/>} exact/>\
          <Route path='/thankyou' element={<ThankYouScreen/>} exact/>\
          <Route path='*' element={<PageNotFoundScreen/>} />
        </Routes>
      </Router>
      <Footer />
    </>
  )
}
export default App
