import React from 'react'
const contactDetails = [
  { name: 'Email', email: 'zimsummercamp@gmail.com', telephone: '' },
  { name: 'Phone', email: '+1 585 831 5410', telephone: '' },
  { name: 'Whatsapp', email: '+1 585 831 5410', telephone: '' },
]

export default function ContactScreen() {
  return (
    <div className="bg-white">
      <header className="relative bg-sky-800 sm:pb-32 pt-12">
        <div className="absolute inset-0">
          <img
            className="w-full h-full object-cover"
            src={require("./../images/phones.jpg")}
            alt="Contact"
          />
          <div
            className="absolute inset-0 bg-gradient-to-l from-sky-800 to-cyan-700 mix-blend-multiply"
            aria-hidden="true"
          />
        </div>

        <div className="relative max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl ">Get in touch</h1>
          <p className="mt-6 text-xl text-cyan-100 max-w-3xl">
            Have a question or want to give us feedback? Our team would be happy to help.
          </p>
        </div>
      </header>

      <main>
        {/* Side-by-side grid */}
        <div className="bg-white">
          <div className="max-w-md mx-auto py-12 px-4 sm:max-w-3xl sm:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="divide-y divide-warm-gray-200">
              <section className="lg:grid lg:grid-cols-3 lg:gap-8" aria-labelledby="contact-heading">
                <h2 id="contact-heading" className="text-2xl font-extrabold text-warm-gray-900 sm:text-3xl">
                  Get in touch
                </h2>
                <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                  {contactDetails.map((item) => (
                    <div key={item.name}>
                      <h3 className="text-lg font-medium text-warm-gray-900">{item.name}</h3>
                      <dl className="mt-2 text-base text-warm-gray-500">
                        <div>
                          <dt className="sr-only">Email</dt>
                          <dd>{item.email}</dd>
                        </div>
                        <div className="mt-1">
                          <dt className="sr-only">Phone number</dt>
                          <dd>{item.telephone}</dd>
                        </div>
                      </dl>
                    </div>
                  ))}
                </div>
              </section>
              <section className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8" aria-labelledby="workWithUs-heading">
                <h2 id="location-heading" className="text-2xl font-extrabold text-warm-gray-900 sm:text-3xl">
                  Join us
                </h2>
                <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                  Like what we are doing and want to be a part of it? Send an email to zimsummercamp@gmail.com and we will get back to you.
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}