import React from 'react'

const ThankYouScreen = () => {
  return (
    <div className="bg-slate-100 h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h1 className='text-3xl font-extrabold tracking-tight text-gray-800 mb-8 text-center '>
          Your application has been received, thank you! You can expect to hear back from us soon at the email address you provided.
        </h1>
      </div>
     </div>
  )
}

export default ThankYouScreen